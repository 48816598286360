@import "../scss/variables.scss";

.app {
  .side-navigation {
    width: 225px;
    position: fixed;
    bottom: 0;
    top: 0;
  }
  main {
    width: calc(100% - 257px);
    margin-left: 257px;
    height: 100vh;
    overflow: hidden;
  }
  .unauthorized {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h1 {
      color: $color-text-primary;
      font-size: $font-size-small;
      text-transform: uppercase;
    }
  }
}
