@import "../../scss/variables.scss";

.toggle {
  display: flex;
  align-items: center;
  .toggle-inner {
    display: flex;
    align-items: center;
    flex-direction: row;
    border-radius: 50px;
    gap: $space-half;
    overflow: hidden;
    background-color: $color-background-overlay;
    cursor: pointer;
    border: 2px solid $color-background-overlay;
    user-select: none;
    .circle {
      width: $font-size-medium;
      height: $font-size-medium;
      background-color: $color-background-primary;
      border-radius: 50px;
    }
    .status {
      margin-right: $space-half;
      font-size: $font-size-small;
      color: $color-text-primary;
    }
    &.on {
      flex-direction: row-reverse;
      .circle {
        background-color: $color-background-emphasis;
      }
      .status {
        margin-right: 0;
        margin-left: $space-half;
      }
    }
  }
}
