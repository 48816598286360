@import "../../scss/variables.scss";

.select {
  display: flex;
  align-items: center;
  .select-container {
    display: flex;
    align-items: center;
    color: $color-text-primary;
    background-color: rgba(37, 39, 40, 0.5);
    border: 1px solid $color-border-primary;
    border-radius: 5px;
    & > div {
      width: 100%;
    }
    .react-select {
      cursor: pointer;

      &__menu {
        font-size: $font-size-small;
        background-color: $color-background-overlay;
        border-radius: 5px;
      }
      &__control {
        color: $color-text-primary;
        font-size: $font-size-small;
        outline: 0;
        background-color: transparent;
        border: 0;
      }
      &__option {
        line-height: 1rem;
      }
      &__option--is-focused {
        background-color: $color-background-emphasis;
      }
      &__option--is-selected {
        background-color: $color-background-emphasis;
      }
      &__indicator-separator {
        display: none;
      }
      &__input-container {
        color: $color-text-primary;
        font-size: $font-size-small;
      }
      &__single-value {
        color: $color-text-primary;
      }
    }
  }
}
