@import "../../scss/variables.scss";

.search {
  margin-top: $space-normal * 2;
  margin-bottom: $space-normal * 2;
  .label {
    margin-bottom: $space-normal;
    padding-left: $space-normal;
    h4 {
      font-size: $font-size-small;
      text-transform: uppercase;
      font-weight: 500;
      color: $color-text-deemphasis;
    }
  }
  .top-search-field-container {
    border-radius: 10px;
    overflow: hidden;
    background-color: $color-background-overlay;
    display: flex;
    .field {
      flex-grow: 1;
      input {
        color: $color-text-primary;
        border: 0;
        outline: 0;
        background-color: transparent;
        font-size: $font-size-regular;
        padding: $space-half * 1.5 $space-normal;
        padding-bottom: $space-half !important;
        padding-right: $space-half * 1.5 !important;
      }
    }
    button {
      border: 0;
      outline: 0;
      flex-shrink: 0;
      flex-grow: 1;
      color: $color-text-primary;
      background-color: transparent;
      cursor: pointer;
    }
  }
  &.disabled {
    .search-field-container {
      opacity: 0.25;
    }
  }
}
