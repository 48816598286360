@import "../../scss/variables.scss";

.client {
  height: 100%;
  overflow-y: scroll;
  display: block;
  .client-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $space-normal;
    border-bottom: 1px solid $color-border-primary;
    h1 {
      font-size: $font-size-small;
      color: $color-text-primary;
      margin-top: $space-quarter;
      text-transform: uppercase;
      strong {
        font-weight: 500;
      }
    }
  }
  .client-body {
    padding: $space-normal;
    border-bottom: 1px solid $color-border-primary;
    .pair {
      margin-bottom: $space-normal;
      .pair-name {
        color: $color-text-deemphasis;
        margin-bottom: $space-half;
        font-size: $font-size-small;
        text-transform: uppercase;
      }
      .pair-value {
        color: $color-text-primary;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .client-premises {
    .client-premises-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $space-normal;
      padding-bottom: $space-half;
      color: $color-text-deemphasis;
      margin-bottom: $space-half;
      font-size: $font-size-small;
      text-transform: uppercase;
    }
  }
}
