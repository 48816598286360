@import "../../scss/variables.scss";

.modal-container {
  background-color: rgba($color-background-overlay, 0.9);
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  .modal {
    min-width: 350px;
    border-radius: 5px;
    background-color: $color-background-primary;
    display: flex;
    flex-direction: column;
    .modal-header {
      border-bottom: 1px solid $color-border-primary;
      padding: $space-half;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        font-size: $font-size-small;
        color: $color-text-deemphasis;
        margin-top: $space-quarter;
        text-transform: uppercase;
        strong {
          font-weight: 500;
        }
      }
      button.close-modal {
        -webkit-appearance: none;
        border: 0;
        outline: 0;
        color: $color-text-primary;
        background-color: transparent;
        font-size: $font-size-regular;
        line-height: 0;
        padding: $space-quarter $space-half;
        margin: 0;
        cursor: pointer;
      }
    }
    .modal-body {
      padding: $space-normal $space-half;
      .radios {
        display: flex;
        .radio {
          display: flex;
          align-items: center;
          .label {
            margin-left: $space-half;
          }
        }
      }
      .status {
        color: $color-text-primary;
        font-size: $font-size-small;
        margin-top: $space-normal;
      }
    }
    .modal-footer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      border-top: 1px solid $color-border-primary;
      padding: $space-half;
    }
  }
}
