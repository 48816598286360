@import "../../scss/variables.scss";

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.create-documents {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: $space-normal;
  display: block;
  .create-documents-body {
    width: 500px;
    margin: 0 auto;
    display: block;
    &.center {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100%;
    }
    .create-documents-form {
      padding-bottom: 50px;
      .create-documents-form-group {
        .group-label {
          margin: $space-normal 0;
          padding-bottom: $space-quarter;
          padding-left: $space-quarter;
          display: flex;
          justify-content: space-between;
          align-items: center;
          h4 {
            font-size: $font-size-small;
            text-transform: uppercase;
            font-weight: 500;
            color: $color-text-primary;
            position: relative;
          }
        }
        .group-fields {
          .boolean-field {
            display: flex;
            align-items: center;
            justify-content: space-between;
            h4 {
              font-size: $font-size-small;
              font-weight: 500;
              color: $color-text-primary;
              position: relative;
            }
            .boolean-field-options {
              display: flex;
              border: 1px solid $color-border-primary;
              border-radius: 5px;
              margin-left: $space-half;
              overflow: hidden;
              button {
                outline: 0;
                border: 0;
                -webkit-appearance: none;
                padding: $space-half $space-normal;
                color: $color-text-primary;
                background-color: transparent;
                cursor: pointer;
                &:first-child {
                  border-right: 1px solid $color-border-primary;
                }
                &.selected {
                  background-color: $color-background-emphasis;
                }
              }
            }
          }
          .field {
            display: flex;
            flex-direction: column;
            label {
              color: $color-text-deemphasis;
              font-size: $font-size-small;
              display: block;
              margin: $space-half 0;
            }
            input {
              outline: 0;
              border: 0;
              -webkit-appearance: none;

              border-radius: 5px;
              flex-grow: 1;
              padding: $space-half;
              border: 1px solid $color-border-primary;
              background-color: rgba($color-background-overlay, 0.5);
              color: $color-text-primary;
              margin-bottom: $space-half;
            }
          }
        }
      }
      .create-documents-form-buttons {
        margin-top: $space-half;
        text-align: right;
        &.flex {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        button {
          outline: 0;
          border: 0;
          -webkit-appearance: none;
          padding: $space-half $space-normal;
          color: $color-text-primary;
          background-color: transparent;
          cursor: pointer;
          &.create {
            margin-left: $space-half;
            background-color: $color-background-emphasis;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
