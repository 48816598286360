@import "../../scss/variables.scss";

.links {
  margin-top: $space-normal;
  .label {
    margin: $space-normal 0;
    padding-left: $space-normal;
    h4 {
      font-size: $font-size-small;
      text-transform: uppercase;
      font-weight: 500;
      color: $color-text-deemphasis;
    }
  }
  .links-container {
    .link {
      color: $color-text-primary;
      text-decoration: none;
      font-size: $font-size-regular;
      padding: $space-half * 1.5 $space-normal;
      border-radius: 10px;
      margin-top: $space-half;
      display: flex;
      align-items: center;
      &.active {
        background-color: $color-background-emphasis;
        &:hover {
          background-color: $color-background-emphasis;
        }
      }
      &:hover {
        background-color: $color-background-overlay;
      }
    }
  }
}
