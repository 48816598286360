@import "../scss/variables.scss";

.field {
  display: flex;
  flex-direction: column;
  label {
    color: $color-text-deemphasis;
    font-size: $font-size-small;
    display: block;
    margin: $space-half 0;
  }
  input {
    outline: 0;
    border: 0;
    -webkit-appearance: none;

    border-radius: 5px;
    flex-grow: 1;
    padding: $space-half;
    border: 1px solid $color-border-primary;
    background-color: rgba($color-background-overlay, 0.5);
    color: $color-text-primary;
    margin-bottom: $space-half;
  }
}
