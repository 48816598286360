@import "../../scss/variables.scss";

.button {
  -webkit-appearance: unset;
  border: 0;
  outline: 0;
  font-family: "IBM Plex Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: $space-half $space-normal;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: $space-third;
  &.primary {
    background-color: #518df7;
  }
  &.flat {
    color: $color-text-blue;
    padding: 0;
    background-color: transparent;
  }
}
