@import "../../scss/variables.scss";

.templates {
  .template-actions {
    display: flex;
    align-items: center;
    gap: $space-normal;
    .replace-file {
      display: flex;
      align-items: center;
      gap: $space-quarter;
      color: $color-text-green;
    }
    .template-download {
      color: $color-text-blue;
      display: flex;
      align-items: center;
      gap: $space-quarter;
      a {
        color: $color-text-blue;
        text-decoration: none;
      }
    }
  }
}
