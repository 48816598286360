@import "../../scss/variables.scss";

.created-documents-list {
  margin-top: -$space-half;
  .created-document-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $space-half 0;
    padding-left: $space-half;
    border-bottom: 1px solid $color-border-primary;
    gap: $space-half;
    .meta {
      color: $color-text-primary;
      display: flex;
      align-items: center;
      gap: $space-half;
      .meta-file {
        display: flex;
        flex-direction: column;
        span.filename {
          font-size: $font-size-small;
        }
        span.type {
          color: $color-text-deemphasis;
          font-size: $font-size-small;
          margin-top: $space-quarter / 2;
        }
      }
    }
    .link {
      a {
        font-size: $font-size-small;
        color: $color-text-blue;
        text-decoration: none;
      }
    }
  }
}
