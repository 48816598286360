@import "../../scss/variables.scss";

.recent-jobs {
  margin-top: $space-normal * 2;
  .label {
    margin-bottom: $space-normal;
    padding-left: $space-normal;
    h4 {
      font-size: $font-size-small;
      text-transform: uppercase;
      font-weight: 500;
      color: $color-text-deemphasis;
    }
  }
  .jobs {
    display: block;
    .job {
      display: flex;
      align-items: center;
      margin-bottom: $space-half;
      border-radius: 10px;
      border: 1px dashed $color-border-primary;
      padding: $space-half $space-normal;
      .job-icon {
        margin-right: $space-half;
        color: $color-text-deemphasis;
      }
      .job-metadata {
        flex-grow: 1;
        h4,
        h5 {
          font-size: $font-size-small;
          color: $color-text-deemphasis;
        }
        h4 {
          font-weight: bold;
        }
        h5 {
          margin-top: $space-quarter;
        }
      }

      &.PROCESSING {
        @keyframes rotation {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(359deg);
          }
        }

        .job-icon {
          svg {
            animation: rotation 2s infinite linear;
          }
        }
      }

      &.COMPLETED {
        .job-icon {
          color: $color-text-green;
        }
        border-color: $color-text-green;
        h4,
        h5 {
          color: $color-text-green;
        }
      }

      &.FAILED {
        .job-icon {
          color: $color-text-red;
        }
        border-color: $color-text-red;
        h4,
        h5 {
          color: $color-text-red;
        }
      }
    }
  }
}
