@import "../../scss/variables.scss";

table {
  width: 100%;
  // max-height: 100%;
  thead {
    text-align: left;
    position: sticky;
    top: 0;
    tr {
      th {
        color: $color-text-deemphasis;
        font-size: $font-size-small;
        text-transform: uppercase;
        font-weight: 500;
        padding: $space-half $space-normal;
        background-color: $color-background-overlay;
      }
    }
  }
  tbody {
    flex: 1;
    height: 100%;
    tr {
      td {
        color: $color-text-primary;
        font-size: $font-size-small;
        padding: $space-half $space-normal;
        a.directions {
          display: flex;
          gap: $space-quarter;
          color: $color-text-deemphasis;
          text-decoration: none;
          &:hover {
            color: $color-text-blue;
          }
        }
        a.link {
          color: $color-text-primary;
        }
      }
      &:nth-child(even) {
        td {
          background-color: rgba($color-background-overlay, 0.5);
        }
      }
    }
  }
}
