@import "../../scss/variables.scss";

.top {
  h1 {
    font-size: $font-size-regular;
    color: $color-text-primary;
  }
  h2 {
    font-size: $font-size-small;
    color: $color-text-deemphasis;
    margin-top: $space-quarter;
    text-transform: uppercase;
  }
}
