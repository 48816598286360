@import "../../scss/variables.scss";

.loading-container {
  display: flex;
  align-items: center;
  .spinner,
  .spinner:after {
    border-radius: 50%;
    width: $space-normal * 1.75;
    height: $space-normal * 1.75;
  }
  .spinner {
    font-size: $space-normal * 1.75;
    position: relative;
    text-indent: -9999em;
    border-top: 2px solid rgba(255, 255, 255, 0.2);
    border-right: 2px solid rgba(255, 255, 255, 0.2);
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    border-left: 2px solid $color-text-blue;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 0.7s infinite linear;
    animation: load8 0.7s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  span {
    color: $color-text-deemphasis;
    font-size: $font-size-small;
    font-weight: 500;
    margin-left: $space-half;
  }
}
