@import "../../scss/variables.scss";

.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $space-normal;
    border-bottom: 1px solid $color-border-primary;
    .total-count {
      h2 {
        font-size: $font-size-small;
        color: $color-text-deemphasis;
        margin-top: $space-quarter;
        text-transform: uppercase;
        strong {
          font-weight: 500;
        }
      }
    }
    .filters {
      display: inline-flex;
      gap: $space-normal;
      align-items: center;
      .date-filter {
        display: flex;
        align-items: center;
        .field {
          input {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .page-body {
    flex-grow: 1;
    overflow-y: scroll;
  }
  .page-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $color-border-primary;
    padding: $space-half $space-normal;
    span {
      color: $color-text-primary;
      font-size: $font-size-small;
      text-transform: uppercase;
      strong {
        font-weight: 500;
      }
    }
    button {
      color: $color-text-primary;
      appearance: none;
      outline: 0;
      border: 0;
      padding: 0;
      background-color: transparent;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      background-color: $color-background-overlay;
      padding: $space-half;
      border-radius: 10px;
      &:first-child {
        margin-right: $space-half;
      }
    }
  }
}
