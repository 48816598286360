$color-background-primary: #161819;
$color-background-overlay: #252728;
$color-background-emphasis: #518df7;

$color-background-green: #87cc9c;
$color-background-red: #eb7b55;

$color-text-primary: #fffffd;
$color-text-deemphasis: #64676c;
$color-text-blue: #518df7;
$color-text-green: #87cc9c;
$color-text-red: #eb7b55;

$color-border-primary: #252728;

$space-normal: 1rem;
$space-three-thirds: 0.75rem;
$space-half: $space-normal / 2;
$space-third: $space-normal / 3;
$space-quarter: $space-normal / 4;

$font-size-large: 1.75rem;
$font-size-medium: 1.25rem;
$font-size-regular: 1rem;
$font-size-small: 0.75rem;
